






















































































































































































import { Vue, Component, Provide } from "vue-property-decorator";
import serve from "@/serve/order";
import Tarbar from "@/components/tarbar.vue";
import moment from "moment";
import { Toast } from "vant";
// import { Dialog } from "vant";
@Component({
  components: {
    Tarbar,
  },
})
export default class Order extends Vue {
  page = 0;
  QQdata = [];
  rate = 0
  item = {}
  showDia = false;
  showSHOU = false
  orderData = [];
  active = "0,1,2,3,4,5,6,7,8,9";
  orderId = "";
  @Provide() loading = false; //是否处于加载状态
  @Provide() finished = false; //是否已加载完所有数据
  @Provide() isLoading = false; //是否处于下拉刷新状态
  @Provide() tabsData = [
    { key: "全部", value: "0,1,2,3,4,5,6,7,8,9" },
    { key: "待整理", value: "0,1" },
    { key: "待发货", value: "2,6" },
    { key: "待收货", value: "3,7" },
    { key: "已完成", value: "4" },
    { key: "已取消", value: "5,9" },
  ];
  @Provide() goodsImg = require("@/assets/img/goods.jpg");
  @Provide() cancleShow = false;
  created() {
    this.getData();
  }
  private tabChange(): void {
    this.orderData = [];
    this.page = 0;
    this.getData();
  }
  private goTo(val: string): void {
    this.$router.push(val);
  }
  timeFormat(val: string): string {
    return moment(val).format("YYYY-MM-DD HH:mm:ss");
  }
  private formatStatus(val: object): string {
    const value = val["refund"].status;
    if (val["refund"] && val["refund"].type === 0) {
      const map = {
        0: "退货待审核",
        1: "同意退货",
        2: "不同意退货",
        3: "处理完毕",
      };
      return map[value];
    } else if (val["refund"] && val["refund"].type === 1) {
      const map = {
        0: "退货退款待审核",
        1: "同意退货退款",
        2: "不同意退货退款",
        3: "处理完毕",
      };
      return map[value];
    }
  }
  ReceivingGoods(val: object): void {
    this.showSHOU = true
    this.item = val
  }
  async shouhuo (){
    const data = {
      id:this.item['id'],
      score:this.rate
    }
    const res =await serve.ReceivingGoods(data)
    if(res.data.code === 0){
      Toast.success('收获成功')
    }else {
      Toast.fail(res.data.message)
    }
  }
  async getData() {
    const res = await serve.getOrderList({
      status: this.active,
      page: this.page,
      size: 10,
    });
    if (res.data.code === 0) {
      res.data.data.content.map((item) => {
        item.merchandise.pic = item.merchandise.pictures.split(",");
        this.orderData.push(item);
      });
      this.loading = false;
      this.isLoading = false;
      if (res.data.data.totalElements <= this.orderData.length) {
        this.finished = true;
      } else {
        this.finished = false;
      }
    }
  }
  chooseQQ(val: object): void {
    this.QQdata = val["qq"];
    this.showDia = true;
  }
  openQQ(val?: string): void {
    window.location.href = `mqqwpa://im/chat?chat_type=wpa&uin=${val}&version=1&src_type=web&web_src=oicqzone.com`;
  }
  private cancleOrder(val: string): void {
    this.orderId = val;
    this.cancleShow = true;
  }
  async cancleOrderDefine() {
    const res = await serve.cancelOrder(this.orderId);
    if (res.data.code === 0) {
      Toast.success("订单已取消");
    } else {
      Toast.fail(res.data.message);
    }
    this.cancleShow = false;
  }
  private orderDetail(id: string): void {
    this.$router.push(`/orderDetail?id=${id}`);
  }
  private onLoad(): void {
    // 上拉加载
    this.loading = true;
    this.page++;
    this.getData();
  }

  private onRefresh(): void {
    //下拉刷新
    this.orderData = [];
    this.isLoading = true;
    this.page = 0;
    this.getData();
  }
}
